<jhi-alert-overlay />
<div
    id="page-wrapper"
    class="page-wrapper"
    [ngClass]="{ 'footer-height-dev': !isProduction || isTestServer, 'no-padding': isShownViaLti }"
    [ngStyle]="{ 'overflow-y': !isTestRunExam && isExamStarted ? 'hidden' : 'auto' }"
    cdkScrollable
>
    <jhi-page-ribbon />
    @if (showSkeleton && !isShownViaLti) {
        <div class="sticky-top">
            <router-outlet name="navbar" />
        </div>
    }
    @if (showSkeleton) {
        <div class="card" style="border-style: none">
            <div class="card-body" [ngClass]="{ 'course-overview pt-0': isCourseOverview }">
                <router-outlet />
            </div>
        </div>
    }
    @if (!showSkeleton) {
        <router-outlet />
    }
    <jhi-notification-popup />
    @if (showSkeleton && !isShownViaLti) {
        <div>
            @if (isTestRunExam || !isExamStarted) {
                <jhi-footer [ngClass]="{ 'footer-height-dev': !isProduction || isTestServer }" />
            }
        </div>
    }
</div>
